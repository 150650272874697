import '@geeckocom/react-ui-kit/dist/styles.css';
import '../styles/app.css';
import '../styles/tailwind.css';
import 'tippy.js/dist/tippy.css';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

interface AppErrors {
  err: any;
}

function GeeckoApp({ Component, pageProps }: AppProps & AppErrors) {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <>
      <Head>
        <title>Geecko Content Service</title>
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <Component {...pageProps} />
        {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  );
}

export default GeeckoApp;
